import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routerTransition } from '../router.animations';
import { AuthService } from '../auth/auth.service';
import { LoginVM } from '../core/models/loginVM';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Common } from '../common/common';
import { LoginService } from '../core/services/login.service';
import { LocalStorageKeys } from '../common/constant';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { MqttSubscribeService } from '../core/services/mqtt-subscribe.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [routerTransition()]
})

export class LoginComponent implements OnInit {

  loginBody: string;
  tokenId: string;
  get LocalStorageKeys() { return LocalStorageKeys; }
  get username() { return this.loginForm.get('username'); }
  get password() { return this.loginForm.get('password'); }

  loginForm: FormGroup = this.formBuilder.group({
    username: ['', [Validators.required, Validators.pattern(Common.emailValidationPattern)]],
    password: ['', Validators.required]
  });

  constructor(
    private loginService: LoginService,
    private authService: AuthService,
    public router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
    private mqttService: MqttSubscribeService,
  ) { }
  loginVM: LoginVM = new LoginVM();

  ngOnInit() {
    this.authService.logout();
    this.SpinnerService.hide();
  }

  onLogin() {
    this.SpinnerService.show();
    this.loginVM = this.loginForm.value;
    let body: HttpParams = new HttpParams();
    body = body.set('username', this.loginVM.username);
    body = body.set('password', this.loginVM.password);
    body = body.set('grant_type', 'password');
    body = body.set('client_id', 'nebulae');
    body = body.set('client_secret', 'nebu!@e');

    if (this.loginForm.valid) {
      this.loginService.login(body).subscribe(response => {
        this.toastr.clear();
        localStorage.setItem(LocalStorageKeys.access_token, JSON.stringify(response));
        Common.userRole = response.roles[0];
        this.loginService.getTokenId().subscribe(responsetoken => {
          this.tokenId = responsetoken.data.applications[0].tokenId;
          localStorage.setItem(LocalStorageKeys.application_token, responsetoken.data.applications[0].tokenId);
          /* ----START :: FOTA LOGIN-----*/
          this.loginService.fotaLogin(this.tokenId).subscribe(responsefota => {
            localStorage.setItem(LocalStorageKeys.fota_access_token, JSON.stringify(responsefota.data.authenticationToken));
            this.SpinnerService.hide();

          });
          /* ----END :: FOTA LOGIN-----*/
        });
        this.loginService.getUserData().subscribe(responseuser => {
          localStorage.setItem(LocalStorageKeys.user, JSON.stringify(responseuser.data));
          this.SpinnerService.hide();
          this.router.navigateByUrl('/dashboard');
        });



        this.mqttService.connectMqtt();
        this.SpinnerService.hide();
      }, err => {
        this.SpinnerService.hide();
        Swal.fire('Error!', err.error.error_description, 'error');
      });
    }
  }

  onLoggedin() {
    localStorage.setItem('isLoggedin', 'true');
  }
}
