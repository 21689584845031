import { HttpClient } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { LoginVM } from '../models/loginVM';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/response/ResponseModel';
import { LoginResponseVM } from '../models/loginResponseVM';
import { Common } from '../../common/common';
import { ApiUrls } from '../../common/api-urls';
import { ApplicationListResponseModel } from '../models/response/ApplicationListResponseModel';
import { ApplicationVM } from '../models/response/ApplicationVM';
import { UserVM } from '../models/userVM';
import { FotaTokenVM } from '../models/response/FotaTokenVM';
import { UrlParameter } from '../../common/constant';
import { FotaVM } from '../models/response/FotaVM';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  @Output() notifyChannel = new EventEmitter();

  constructor(private http: HttpClient) { }

  
  login(body): Observable<LoginResponseVM> {
    const options = { headers: Common.getLoginHeader() };
    return this.http.post<LoginResponseVM>(ApiUrls.loginUrl, body, options);
  }

  getTokenId(): Observable<ResponseModel<ApplicationVM>>{
    const options = { headers: Common.getAuthorizHeader() };
    return this.http.get<ResponseModel<ApplicationVM>>(ApiUrls.getTokenIdUrl, options);
  }
  getUserData(): Observable<ResponseModel<UserVM>>{
    const options = { headers: Common.getAuthorizHeader() };
    return this.http.get<ResponseModel<UserVM>>(ApiUrls.getUserDataUrl, options);
  }

  fotaLogin(tokenId): Observable<ResponseModel<FotaVM>> {
    const options = { headers: Common.getAuthorizHeader() };
    return this.http.get<ResponseModel<FotaVM>>(ApiUrls.fotaLoginUrl.replace(UrlParameter.tokenId, tokenId), options);
  }

  

}
