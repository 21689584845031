import { UrlParameter } from './constant';
import { environment } from '../../environments/environment';


// declare var WebAPIBaseUrl: string;
// declare var FotaWebAPIBaseUrl: string;
declare var apiConfig: any;
export class ApiUrls {
  get UrlParameter() { return UrlParameter; }
  static baseUrl: string = apiConfig.WebAPIBaseUrl;
  static fotaBaseUrl: string = apiConfig.FotaWebAPIBaseUrl;

  // User Module
  static loginUrl = `${ApiUrls.baseUrl}oauth/token`;
  static getTokenIdUrl = `${ApiUrls.baseUrl}nebulae/api/v1/user/applications`;
  static getUserDataUrl = `${ApiUrls.baseUrl}nebulae/api/v1/user/whoami`;
  static allGateways = `${ApiUrls.baseUrl}nebulae/api/v1/nms/userNodes/${UrlParameter.tokenId}/${UrlParameter.status}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static gatewayProperties = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/detail/${UrlParameter.nodeId}`;
  static downloadKeys = `${ApiUrls.baseUrl}nebulae/api/v1/node/keys/${UrlParameter.tokenId}/${UrlParameter.nodeId}`;
  static addBorderRouter = `${ApiUrls.baseUrl}nebulae/api/v1/node`;

  static dashboardUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/dashboard`;
  static dashboardBorderRouterFwUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/gateway/firmware/count`;
  static dashboardEndNodeDeviceFwUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/node/firmware/count`;

  static gatewayRebootUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/reboot`;
  static gatewayFactoryRestUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/factory/reset`;
  static gatewayPingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/ping`;
  static gatewayDumpAllUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/dump/all/request`;
  static gatewayLogCountUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/logging/count`;
  static gatewayConnectionDetailsUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/gateway/connection/detail`;
  static gatewayLoggingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/logging`;
  static gatewayNetworkTree = `${ApiUrls.baseUrl}nebulae/api/v1/nms/network/diagram/${UrlParameter.nodeId}`;
  static gatewayNetworkDiagnostic = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/network/diagnostic/${UrlParameter.nodeId}`;
  static allgatewayNetworkTree = `${ApiUrls.baseUrl}nebulae/api/v1/nms/network/diagram`;
  static gatewayUpTime = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/uptime/chart`;
  static gatewayUpTimeGraphData = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/uptime`;
  static gatewaySystemInfo = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/profile/${UrlParameter.tokenId}/${UrlParameter.nodeId}`;
  static gatewayUpdate = `${ApiUrls.baseUrl}nebulae/api/v1/node`;
  static allPrelaodedDevices = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/devices/list/new/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}&isTestDevice=${UrlParameter.isTestDevice}`;
  static preloadedDeviceUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/device/new`;
  static preloadedWhiteListedDeviceUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/whitelist/device`;
  static updatePreloadedDeviceUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/device/new`;
  static uploadPreloadedDevice = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/devices/list`;
  static downloadSampleCode = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/devices/sample`;
  static gatewayDcuPropertiesData = `${ApiUrls.baseUrl}nebulae/api/v1/nms/dcu/property`;
  static gatewayDcuPropertiesDataLive = `${ApiUrls.baseUrl}nebulae/api/v1/nms/dcu/property/live`;
  static gatewayPropertiesRefreshgUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/property`;
  static gatewayRPLPropertiesRefreshgUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/gateway/rpl/property`;
  static gatewayDownloadLoggingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/log/request`;
  static gatewayNetworkDiagnosticUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/network/diagnostic`;
  static deviceRebootUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/reboot`;
  static deviceFactoryRestUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/factory/reset`;
  static devicePingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/ping`;
  static deviceDumpAllUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/dump/all/request`;
  static deviceLogCountUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/logging/count`;
  static deviceConnectionDetailsUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/node/ncp/connection/detail`;
  static deviceLoggingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/logging`;
  static deviceNetworkDiagnostic = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/network/diagnostic/${UrlParameter.mac}`;
  static deviceNetworkDiagnosticUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/network/diagnostic`;
  static deviceNetworkTree = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/network/diagram/${UrlParameter.mac}`;
  static deviceDeleteUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/device`;
  static deviceNonCommissioned = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/device/noncommissioned`;
  static updateNodePropertiesUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/set/node/connection/detail`;
  static devicePropertiesRefreshgUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/property`;
  static deviceRPLPropertiesRefreshgUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/node/ncp/rpl/property`;
  static deviceDownloadLoggingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/log/request`;
  static gatewayTamperUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/tamper/1/1/0/1/1?orderByColumnIndex=acknowledge&searchString=null`;
  static gatewayAllTamperUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/tamper/3/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static gatewayAllPowerStatusUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/power/status/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static gatewayTamperUpdateUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/tamper`;
  static gatewayTamperUpdateAllUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/tamper/ackall`;
  static allEventLogsUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/activity/${UrlParameter.nodeId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static allFilterEventLogsUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/activity/${UrlParameter.nodeId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static allFilterLoggingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/logging/data/${UrlParameter.endNodeDeviceId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static allFilterNodeLoggingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/logging/data/${UrlParameter.endNodeDeviceId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static allParentSwitchUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/parent/switch/list/${UrlParameter.endNodeDeviceId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static addUser = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/add`;
  static userDeleteUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/delete`;
  static allUsers = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static getuserUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/get/${UrlParameter.userId}`;
  static getUserRoleListUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/role/detail/list`;
  static updateUser = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/update`;
  static userBlock = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/block`;

  static userDeassociateGateways = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/gateway/deassociate`;
  static userAssociateGateways = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/gateway/associate`;
  static userAssociatedGateways = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/get/associate/${UrlParameter.userId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static userDeassociatedGateways = `${ApiUrls.baseUrl}nebulae/api/v1/nms/user/get/deassociate/${UrlParameter.userId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static allNodes = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/list/${UrlParameter.status}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static gatewayUpTimeDemo = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/uptime/chart/test`;
  static allDeviceStatusUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/status/trap/list/${UrlParameter.endNodeDeviceId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static nodeDetailsHistoryURL = `${ApiUrls.baseUrl}nebulae/api/v1/nms/diagnostic/history/data`;
  static allFilterEventsUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/gateway/tamper/3/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static gatewayPowerStatusUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/dcu/power/status/${UrlParameter.nodeId}/${UrlParameter.mac}`;
  static gatewayPowerStatusRefreshUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/power/status/${UrlParameter.mac}/${UrlParameter.nodeId}`;
  static gatewayPowerStatusInfoUrl = `${ApiUrls.baseUrl}nebulae/api/v2/nms/gateway/powerStatus/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static gatewayDropdownURL = `${ApiUrls.baseUrl}nebulae/api/v1/nms/getdcunamelist/${UrlParameter.tokenId}`;
  /* FoTa API URL */
  //static fotaLoginUrl = `${ApiUrls.fotaBaseUrl}nebulink/fota/oauth/login`;
  static fotaLoginUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/fota/userdetails/${UrlParameter.tokenId}`;
  static fotaDeviceListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/device/list/${UrlParameter.status}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static fotaDeviceStatusUrl = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/device/status`;
  static fotaDeviceArtifactListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/device/artifacts/list/${UrlParameter.mac}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static fotaDeviceGroupListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/device/groups/list/${UrlParameter.mac}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static fotaDeviceDeleteUrl = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/device/${UrlParameter.nodeId}`;
  static fotDeviceInfo = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/device/${UrlParameter.nodeId}`;
  static fotaGroupListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/group/list/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static addFotaGroup = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/group`;
  static fotGroupInfo = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/group/${UrlParameter.groupId}`;
  static fotaNodeListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/device/node/list/${UrlParameter.mac}/${UrlParameter.status}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static fotaChildDeviceStatusUrl = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/device/child/status`;


  static fotaDeviceAssociateListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/group/except/nodes/list/${UrlParameter.groupId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static associateFotaGroupdeviceURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/group/associate/nodes`;
  static fotaDeviceDeassociateListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/group/nodes/list/${UrlParameter.groupId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static deassociateFotaGroupdeviceURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/group/deassociate/nodes`;
  static associatedDeviceListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/group/nodes/list/${UrlParameter.groupId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;


  static fotaArtifactListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/artifact/list/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static addFotaArtifact = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/artifact`;
  static fotArtifactInfo = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/artifact/${UrlParameter.artifactId}`;
  static fotaArtifactDownloadReleaseFile = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/artifact/download/${UrlParameter.artifactId}`;
  static fotaArtifactDelete = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/artifact/${UrlParameter.artifactId}`;

  static fotaDeploymentListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/deployment/list/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static fotaDeploymentDeviceListURL = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/deployment/devices/${UrlParameter.deploymentId}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.search}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}`;
  static fotDeploymentStatus = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/deployment/status/${UrlParameter.deploymentId}`;
  static updateDeploymentStatus = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/deployment/state`;
  static addFotaDeployment = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/deployment`;
  static deleteDeployment = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/deployment/${UrlParameter.deploymentId}`;
  static exportDeploymentDevices = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/export/device/${UrlParameter.deploymentId}/${UrlParameter.btnId}/${UrlParameter.lblValue}`;
  static exportDeploymentLogs = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/deployment/logs/${UrlParameter.deploymentId}/${UrlParameter.nodeId}`;
  static pendingDeployemntDevices = `${ApiUrls.fotaBaseUrl}nebulink/fota/api/deployment/pending/progress/devices/${UrlParameter.deploymentId}`;

  /* FoTa PAGES URL */
  static viewDeviceArtifactPage = `/fota/devices/${UrlParameter.mac}/artifact`;
  static viewDeviceGroupPage = `/fota/devices/${UrlParameter.mac}/group`;
  static viewChildDevicePage = `/fota/devices/${UrlParameter.mac}/node`;
  static viewDeviceAssociatePage = `/fota/groups/${UrlParameter.groupId}/${UrlParameter.groupName}/associate`;
  static viewDeviceDeassociatePage = `/fota/groups/${UrlParameter.groupId}/${UrlParameter.groupName}/deassociate`;
  static viewDeviceAssociatedPagePage = `/fota/groups/associated-devices/${UrlParameter.groupId}/${UrlParameter.groupName}`;
  static viewDeployemntProgressPage = `/fota/deployments/progessstatus/${UrlParameter.deploymentId}`;

  static viewGatewayListPage = `/gateways/${UrlParameter.status}`;
  static updateUserPage = `/users/edit-user/${UrlParameter.userId}`;
  static userGatewaysPage = `/users/user-border-routers/${UrlParameter.userId}`;
  static viewGatewayDetailsPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/info`;
  static viewGatewayDetailsUptimePage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/uptime`;
  static viewGatewayDetailsDeviceLogPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/logs`;
  static viewGatewayDetailsEventPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/events`;
  static viewGatewayDetailsCommunicationPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/communication`;
  static viewGatewayDetailsDevicesPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/device`;
  static viewGatewayDetailsNetworkPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/network-graph`;
  static viewHistoryPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/history`;
  static viewAssociatePage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/associate`;
  static allDevices = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/devices/${UrlParameter.nodeId}/${UrlParameter.status}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static deviceInfo = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/device/info/${UrlParameter.mac}`;
  static viewDeviceDetailsPage = `/gateways/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/device/${UrlParameter.endNodeDeviceId}`;
  static viewNodeDetailsPage = `/nodes/list/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/device/${UrlParameter.endNodeDeviceId}`;
  static viewRepeatedNodeDetailsPage = `/repeated-nodes/list/view/${UrlParameter.nodeId}/${UrlParameter.deviceId}/device/${UrlParameter.endNodeDeviceId}`;
  static deviceDetailInfo = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/${UrlParameter.mac}`;
  static deviceTimeSeriesGraph = `${ApiUrls.baseUrl}nebulae/api/v1/nms/diagnostic/series/data`;
  static getAlldeviceIds = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/device/array`;
  static viewEndNodeDeviceDetailsPage = `/nodes/detail/${UrlParameter.endNodeDeviceId}`;


  /* repeated nodes PAGES URL */
  static AddRepeaterSingle = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/repeater`;
  static AddRepeaterBulk = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/repeater/list`;
  static PreloadedRepeaterList = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/repeater/list/new/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}&isTestDevice=${UrlParameter.isTestDevice}`;
  static RepeaterStatuswiseList = `${ApiUrls.baseUrl}nebulae/api/v1/nms/repeater/list/${UrlParameter.status}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static ConnectedDisconnectedendnodeList = `${ApiUrls.baseUrl}nebulae/api/v1/nms/repeater/${UrlParameter.mac}/${UrlParameter.status}/${UrlParameter.draw}/${UrlParameter.offset}/${UrlParameter.limit}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`;
  static downloadSamplerepeatercode = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/repeater/sample`;
  static gatewayHistorydata = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/history/data/${UrlParameter.pageStartingIndex}/${UrlParameter.pageEndingIndex}`;
  static deviceHistorydata = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/history/data/${UrlParameter.pageStartingIndex}/${UrlParameter.pageEndingIndex}`;

  /* Multicast Notification*/
  static gatewaymulticastnotificationPingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/ping/list`;
  static gatewaymulticastnotificationRebootUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/reboot/list`;
  static gatewayFactoryRestUrlmulticastnotificationUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/gateway/factory/reset/list`;
  static gatewayDcuPropertiesDataLivemulticastnotificationUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/dcu/property/live/list`;
  static devicemulticastnotificationPingUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/ping/list`;
  static devicemulticastnotificationRebootUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/reboot/list`;
  static deviceFactoryRestUrlmulticastnotificationUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/factory/reset/list`;
  static deviceDcuPropertiesDataLivemulticastnotificationUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/property/list`;

  /* Power Outage*/
  static getPowerOutage = `${ApiUrls.baseUrl}nebulae/api/v1/nms/power/outage/${UrlParameter.starttime}/${UrlParameter.endtime}`;

  /* Associate Page*/
  static getAssociateStatus = `${ApiUrls.baseUrl}nebulae/api/v1/nms/auth/status/${UrlParameter.deviceId}`;
  static getAssociateSelectedNodeUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/auth/associate/${UrlParameter.deviceId}/${UrlParameter.draw}/${UrlParameter.pageStartingIndex}/${UrlParameter.pageEndingIndex}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`
  static getAssociateNodeList = `${ApiUrls.baseUrl}nebulae/api/v1/nms/preloaded/auth/devices/${UrlParameter.draw}/${UrlParameter.pageStartingIndex}/${UrlParameter.pageEndingIndex}/${UrlParameter.orderBy}?orderByColumnIndex=${UrlParameter.orderColumn}&searchString=${UrlParameter.searchParameter}`
  static updateAssociateStatus = `${ApiUrls.baseUrl}nebulae/api/v1/nms/auth/devices/list/${UrlParameter.deviceId}/${UrlParameter.authStatus}`
  static associateDeassociateNodeListUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/auth/devices/list/${UrlParameter.deviceId}`
  static disassociatedNodeUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/auth/devices/list/delete/${UrlParameter.deviceId}`
  static associatedNodeUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/auth/devices/list/${UrlParameter.deviceId}`


  /* Configuration Node Page */
  static configUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/config/detail/${UrlParameter.nodeId}/${UrlParameter.endNodeDeviceId}`;
  static configGetFromDeviceUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/get/node/config/detail/${UrlParameter.nodeId}/${UrlParameter.endNodeDeviceId}`;
  static updateConfigData = `${ApiUrls.baseUrl}nebulae/api/v1/nms/set/node/config/detail`;
  static addMultiNodeConfigUrl = `${ApiUrls.baseUrl}nebulae/api/v1/nms/node/ncp/config/list`

}


