import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { Observable, throwError, AsyncSubject, BehaviorSubject } from 'rxjs';
import { tap, catchError, switchMap, finalize, filter, take, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { LoginService } from '../core/services/login.service';
import { LocalStorageKeys } from '../common/constant';
//import 'rxjs/add/operator/switchMap';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/operator/do';
import { Common } from '../common/common';
import { trim } from 'lodash';



@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    isRefreshingToken = false;
    constructor(public toasterService: ToastrService, private authService: AuthService, private router: Router,
        private loginService: LoginService) { }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if (evt.body && evt.body.success) {
                        this.toasterService.success(
                            evt.body.success.message, evt.body.success.title,
                            {
                                positionClass: 'toast-bottom-center'
                            });
                    }
                }
            }),
            catchError((err: any) => {

                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        console.log('err 401', err)
                        if (err.error.error === 'invalid_token') {
                            const getInvalidToken = err.error.error_description.split(':');
                            const invalidToken = trim(getInvalidToken[1]);
                            if (Common.FotaToken === invalidToken) {
                                // Fota Token expired! 
                                const tokenId = localStorage.getItem(LocalStorageKeys.application_token);
                                return this.loginService.fotaLogin(tokenId).pipe(switchMap(response => {
                                    if (response) {
                                        localStorage.setItem(LocalStorageKeys.fota_access_token, JSON.stringify(response.data.authenticationToken));
                                        Common.FotaToken = response.data.authenticationToken.access_token;
                                        const newRequest = req.clone({
                                            headers: req.headers.set('Authorization',
                                                'Bearer ' + response.data.authenticationToken.access_token)
                                        });
                                        return next.handle(newRequest);
                                    }
                                }));
                            } else {

                                // Portal Token Expired!

                                this.router.navigate(['login']);
                            }

                        } else {
                            this.router.navigate(['forbidden']);
                        }

                    } else if (err.status === 403) {
                        this.router.navigate(['forbidden']);
                    } else if (err.status === 0) {
                        this.router.navigate(['server-down']);
                    }
                }
                return throwError(err);
                // return of(err);
                // return empty<HttpEvent<any>>();
            }
                // return throwError(err);
            ));
    }

    handleResponse(req: HttpRequest<any>, event) {
        console.log('Handling response for ', req.url, event);
        if (event instanceof HttpResponse) {
            console.log('Request for ', req.url,
                ' Response Status ', event.status,
                ' With body ', event.body);
        }
    }

    handleError(req: HttpRequest<any>, event) {
        console.error('Request for ', req.url,
            ' Response Status ', event.status,
            ' With error ', event.error);

        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (chunkFailedMessage.test(event.message)) {
            window.location.reload();
        }
        console.error(event);

    }

}