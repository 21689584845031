import { Component } from '@angular/core';
import { MqttSubscribeService } from './core/services/mqtt-subscribe.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(private mqttService:MqttSubscribeService,) {}

    ngOnInit() {
        const isLogin = JSON.parse(localStorage.getItem('user'));
        if(isLogin){
            this.mqttService.connectMqtt();
        }
    }
}
