import { Injectable } from '@angular/core';
// import { MqttSubscribeService } from '../services/mqtt-subscribe.service';
import { LocalStorageKeys } from '../common/constant';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { MqttSubscribeService } from '../core/services/mqtt-subscribe.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  errorData: {};
  get LocalStorageKeys() { return LocalStorageKeys; }
  constructor(private http: HttpClient, private mqttService: MqttSubscribeService,
    // public mqttSubscribeService: MqttSubscribeService
  ) { }

  redirectUrl: string;

  isLoggedIn() {
    if (localStorage.getItem(LocalStorageKeys.user)) {
      const currentUser = JSON.parse(localStorage.getItem(LocalStorageKeys.user));
      const expiredate = new Date(currentUser.expiresIn);
      const currentDate = new Date();
      if (expiredate < currentDate) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  getAuthorizationToken() {
    const currentUser = JSON.parse(localStorage.getItem(LocalStorageKeys.user));
    return currentUser.token;
  }
  getAuthorizationFotaToken() {
    const currentUser = JSON.parse(localStorage.getItem(LocalStorageKeys.fota_access_token));
    return currentUser.access_token;
  }

  logout() {
    localStorage.clear();
    if (this.mqttService.isMqttConnected) {
      this.mqttService.disConnect();
    }

  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message
    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }
}
