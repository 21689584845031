export const flatten = filter => {
    const filters = (filter || {}).filters;
    if (filters) {
        return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
    }
    return [];
};

export enum GroupModuleChangeEvent {
    OnModuleEdit = 'edit',
    OnModuleDelete = 'delete',
    OnModuleView = 'view',
}

export enum DeleteConfirmModal {
    ConfirmButtonText = 'Yes',
    CancelButtonText = 'No',
}

export enum RandomKeyConfigModal {
    Max32CharcterKey = 32,
    Max16CharcterKey = 16,
    possibleCharcterString = 'abcdef0123456789'
}

export enum RandomKeyTypeModal {
    AppKey = 'app_key',
    GenAppKey = 'gen_app_key',
    NetworkKey = 'network_key',
}

export enum PermissionRoleModal {
    IsViewable = 'isViewable',
    IsDeletable = 'isDeletable',
    IsEditable = 'isEditable',
}
export enum ModuleIdsModal {
    ModuleId01 = 'M01',
    ModuleId02 = 'M02',
    ModuleId03 = 'M03',
    ModuleId04 = 'M04',
    ModuleId05 = 'M05',
    ModuleId06 = 'M06',
}

export enum DeviceDataTypeModal {
    Uplink = 'uplink',
    Join = 'join',
    Status = 'status',
    Downlink = 'downlink',
    Ack = 'ack',
    Error = 'error',
    Location = 'location',
    Downlinkack = 'downlinkack'
}

export enum LocalStorageKeys {
    nodeStatusId = 'nodeStatusId',
    user = 'user',
    access_token = 'access_token',
    application_token = 'application_tokenid',
    node_id = 'node_id',
    ncpMac = 'ncpMac',
    deviceId = 'deviceId',
    hostEvent = 'hostEvent',
    ncpEvent = 'ncpEvent',
    deviceEvent = 'deviceEvent',
    gatewayStatus = 'gatewayStatus',
    deviceStatus = 'deviceStatus',
    nodeStatus = 'nodeStatus',
    gatewayLogging = 'gatewayLogging',
    nodeLogging = 'nodeLogging',
    tabName = 'tabName',
    deviceTabName = 'deviceTabName',
    fota_access_token = 'fota_access_token',
    nonCommissioned ='Non_Commissioned_Status',
    searchString ='searchString',
    searchStringRepeater ='searchStringRepeater'
}

export enum UrlParameter {
    starttime = 'starttime',
    endtime = 'endtime',
    nodestatus = 'nodestatus',
    pageStartingIndex = 'pageStartingIndex',
    pageEndingIndex = 'pageEndingIndex',
    description = 'description',
    repeater = 'repeater',
    tokenId = 'tokenId',
    limit = 'limit',
    offset = 'offset',
    orderBy = 'orderBy',
    orderColumn = 'orderColumn',
    search = 'search',
    draw = 'draw',
    status = 'status',
    nodeId = 'nodeId',
    deviceId = 'deviceId',
    mac = 'mac',
    endNodeDeviceId = 'endNodeDeviceId',
    userId = 'userId',
    groupId = 'groupId',
    groupName = 'groupName',
    artifactId = 'artifactId',
    deploymentId = 'deploymentId',
    btnId = 'btnId',
    lblValue = 'lblValue',
    searchParameter = 'searchParameter',
    name = 'name',
    authStatus = 'authStatus',
    isTestDevice ='isTestDeviceValue'
}

export enum PageTitle {
    addPageTitle = 'Add',
    editPageTitle = 'Edit',
}

export enum gatewayRebootReason {
    Unknow = "Unknow Reason",
    userReset = "User Reset Device",
    factoryReset = "Factory Reset",
    firmwareUpdateReset = "firware update reset",
    brownOutReset = "brownout reset",
    watchDogReset = "watchdog reset"
}

export enum loggingStatus {
    Enable = 'ENABLE',
    Disable = 'DISABLE',
}
export enum loggingStatusName {
    Enable = 'Enable',
    Disable = 'Disable',
}
export enum userRole {
    Integrator = 'INTEGRATOR',
    Operator = 'OPERATOR',
    Analysis = 'ANALYSIS',
    Admin = 'ADMIN'
}

export enum AlertTitle {
    Error = 'Error !',
    Success = 'Success',
    ConfirmAlert = 'Are you sure?',
}
export enum AlertType {
    Error = 'error',
    Success = 'success',
    Warning = 'warning',
}

export enum AlertMessage {
    DataNotFound = 'Data not found!!',
    Dateselect = 'Please select Date!!',
    DevicePropertiesUpdateSuccess = 'Device Properties updated successfully',
    DeviceReady = 'Device  is ready',
    SomethingWentWrong = 'Something went wrong!! Please try again',
    LogRequestSuccess = ' Log request successfully..',
    LogCount = 'Device Log Count',
    EraseLogConfirm = 'You want to erase log!.',
    WontRevertMessage = 'You won\'t be able to revert this!',
    DeviceResetSuccess = 'Device reset successfully',
    DeviceLogsDumpSuccess = 'Device logs dumpped',
    LogDownloading = 'Log downloading..',
    ConfirmUpdateBR = 'You want to update border router detail!',
    UpdateBRSuccess = ' Border Router detail updated successfully',
    UpdatedBRPropertiesSuccess = 'Properties updated successfully',
    BRReadySuccess = 'Border Router  is ready',
    DeviceRemovedSuccess = 'Device Removed ',
    BRAddSuceess = 'Border Router added successfully',
    BRLogCountSuccess = 'Border Router Log Count',
    BRLogDumpsuccess = 'Border Router  logs dumpped',
    ConfirmLogLevelUpdate = 'You want to update Log Level ?',
    BRResetSuccess = 'Border Router  reset successfully',
    ACksuccess = 'Acknowledge successfully',
    NodeParentNotFound = 'Node Parents not found',
    DeviceDisconnectSuccess = 'Device disconnected successfully.',
    InvalidFile = 'Invalid file type.Please upload valid csv file.',
    FileTitleInvalid = 'File title must be DeviceId',
    FileEmpty = 'File is empty',
    ConfirmUpdatePreloadDevice = 'You want to update preloaded device!',
    UpdatePreloadedDeviceSuccess = 'Updated Preloaded device successfully',
    AddPreloadedDeviceSuccess = 'Added Preloaded device successfully',
    DeviceDeleteSuccess = 'Device delete successfully.',
    getDeviceConfigData = 'Configuration got !!!',
    UpdateConfiguration = 'Update Configuration',
    setConfigurationUpdate = 'Get Node Configuration Before Set',
    confirmAddPreloadDevice = 'Do you want to add Preloaded Device ?',
    node ='Please Select Node!!!',
    FileTitleForMUltiNodeInvalid = 'File title must be deviceMac',
    deviceNonCommissioned ='Status Change Successfully'

}

export enum AlertButton {
    ConfirmButtonReset = 'Yes, Reset it!',
    ConfirmButtonReboot = 'Yes, Reboot it!',
    ConfirmButtonUpdate = 'Yes, Update it!',
    ConfirmButtonAdd = 'Yes, Add it!',
    ConfirmButtonDelete = 'Yes, Delete it!',
    ConfirmButtonACKAll = 'Yes, Acknowledge All!',
    ConfirmButtonACK = 'Yes, Acknowledge it!',
    confirmButtonChange ='Yes'

}
export enum AlertButtonColor {
    ConfirmButtonColor = '#3085d6',
    CancelButtonColor = '#d33',
}


