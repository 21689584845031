import { HttpHeaders } from '@angular/common/http';
import { LocalStorageKeys } from './constant';
import { ApiUrls } from './api-urls';
import { IMqttServiceOptions, MqttService } from 'ngx-mqtt';
import { environment } from '../../environments/environment';
import { NameValue } from '../core/models/name-value';

if (JSON.parse(localStorage.getItem('access_token'))) {
  var client = 'access:' + JSON.parse(localStorage.getItem('access_token')).access_token;
}

declare var apiConfig: any;
export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  connectOnCreate: false,
  hostname: apiConfig.mqttHostname,
  port: apiConfig.mqttPort,
  protocol: 'ws',
  path: '/mqtt',
  clientId: client
};
export class Common {
  static token: string;
  static FotaToken: string;
  static userName: string;
  static userRole: string;
  static userId: number;
  static organizationName: string;
  static shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  get LocalStorageKeys() { return LocalStorageKeys; }
  static minLqi = '^(3[0-9]|4[0-9]|50)$';
  static maxRplRank = '^([2-5])$';
  static maxNoOfChild = '^([1-9]|[1-4][0-9]|50)$';
  static freshEtxThreshold = '^(134[89]|13[5-9][0-9]|1[4-9][0-9]{2}|[2-5][0-9]{3}|6[0-3][0-9]{2}|6400)$';
  static nonFreshEtxThreshold = '^(166[4-9]|16[7-9][0-9]|1[7-9][0-9]{2}|[2-5][0-9]{3}|6[0-3][0-9]{2}|6400)$';
  static pushDataSendTimeValue = '^(6[0-9]|[7-9][0-9]|[1-9][0-9]{2}|1[01][0-9]{2}|1200)$';

  static passwordValidationPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#$^+=!*()@%&]).{8,}$';
  // tslint:disable-next-line: max-line-length
  static emailValidationPattern = '^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

  static deviceIdPattern = '^[a-zA-Z0-9@!#^&*(){}|:\'<>,.? $]+$';

  // tslint:disable-next-line: max-line-length
  static singleEmailPattern = '^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

  static nameValidationPattern = '^[a-zA-Z0-9_]+$';

  static hexaValidationPattern = '^[a-fA-F0-9]+$';

  static parseColor(color: any): number {
    if (color < 0) {
      return 0;
    } else if (color > 255) {
      return 255;
    } else {
      return color;
    }
  }

  static isNullOrEmpty(data) {
    if (data) {
      return false;
    } else {
      return true;
    }
  }

  static getUTCDate() {
    return (new Date()).toUTCString();
  }

  static getAuthorizHeader() {
    Common.setToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + Common.token
    });
    return headers;
  }

  static getAuthorizHeaderForZipFile() {
    Common.setToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      //  'responseType': 'arraybuffer',
      Authorization: 'Bearer ' + Common.token
    });

    return headers;
  }




  static addTokenInHeader(token) {
    const headers = new HttpHeaders({
      Authorization: 'bearer ' + token
    });
    return headers;
  }

  static getLoginHeader() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'

    });
    return headers;
  }

  static setToken() {
    if (!Common.isNullOrEmpty(localStorage.getItem(LocalStorageKeys.access_token))) {
      const userModel = JSON.parse(localStorage.getItem(LocalStorageKeys.access_token));
      Common.token = userModel.access_token;
      Common.userId = userModel.userId;
      Common.organizationName = 'Admin';
      Common.userRole = userModel.roles[0];
    } else {
      window.location.replace('/login');
    }
  }

  static parseInt(x, base) {
    const parsed = parseInt(x, base);
    if (isNaN(parsed)) { return 0; }
    return parsed;
  }

  static getOrgData() {
    // const orgData = localStorage.getItem(LocalStorageKeys.OrganizationName);
    // if(orgData){
    //   this.organizationName = orgData;
    // }else{
    //   this.organizationName = '';
    // }
  }
  static timeAgoDateConvert(comparisonDate) {
    let seconds = parseInt(comparisonDate, 10);
    let days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    let hrs = Math.floor(seconds / 3600);
    seconds -= hrs * 3600;
    let mnts = Math.floor(seconds / 60);
    seconds -= mnts * 60;
    let daysString = days + ' days';
    let hrsString = hrs + ' Hrs';
    let mntsString = mnts + ' Mins';
    let secondsString = seconds + ' Secs';
    if (days == 0) {
      daysString = '';
    }
    if (hrs == 0) {
      hrsString = '';
    }
    if (mnts == 0) {
      mntsString = '';
    }
    if (seconds == 0) {
      secondsString = '';
    }
    //return ' From ' + days + ' days, ' + hrs + ' Hrs, ' + mnts + ' Mins, ' + seconds + ' Secs';
    return ' From ' + daysString + ' ' + hrsString + ' ' + mntsString + ' ' + secondsString;
  }

  static getFotaAuthorizHeader() {
    Common.setFotaToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + Common.FotaToken,
      'version': 'v2'
    });
    return headers;
  }
  static getFotaAuthorizHeaderV1() {
    Common.setFotaToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + Common.FotaToken,
      'version': 'v1'
    });
    return headers;
  }
  static getFotaAuthorizHeaderV1WithJSON() {
    Common.setFotaToken();
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + Common.FotaToken,
      'version': 'v1'
    });
    return headers;
  }

  static getAuthorizHeaderForFile() {
    Common.setFotaToken();
    const headers = new HttpHeaders({
      //  'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + Common.FotaToken,
      'version': 'v1'
    });

    return headers;
  }
  static setFotaToken() {
    if (!Common.isNullOrEmpty(localStorage.getItem(LocalStorageKeys.fota_access_token))) {
      const userModel = JSON.parse(localStorage.getItem(LocalStorageKeys.fota_access_token));
      Common.FotaToken = userModel.access_token;
      // Common.FotaToken = 'wrong_token';
    } else {
      //window.location.replace(ApiUrls.baseUrl.replace('/api/', '') + '/login');
    }
  }
  public static nodeSystemInfoProperties: NameValue<string>[] = [{ name: 'App Version', value: 'appversion' }, { name: 'HW Version', value: 'hwVersion' }, { name: 'Product Id', value: 'productId' }, { name: 'Vendor Id', value: 'vendorId' },
  { name: 'SDK Version', value: 'sdkVersion' }, { name: 'RPL Neighbour Count', value: 'neighbourcount' }, { name: 'RPL Rank', value: 'rplRank' }, { name: 'Device Parent', value: 'deviceParent' },
  { name: 'Channel', value: 'channel' }, { name: 'PAN Id', value: 'panId' }, { name: 'TX Power', value: 'txPower' }, { name: 'RPL Etx Threshold Multiplier', value: 'rplEtxThresholdMultiplier' }, { name: 'RPL Lqi Threshold', value: 'rplLqiThreshold' }
  ];


  public static borderrouterSystemInfoProperties: NameValue<string>[] = [{ name: 'Product Id', value: 'productId' }, { name: 'Vendor Id', value: 'vendorId' }, { name: 'S.N', value: 'serialNumber' }, { name: 'Board Version', value: 'boardVersion' }, { name: 'FW Version', value: 'fwVersion' }, { name: 'HW Version', value: 'hwVersion' },
  { name: 'SDK Version', value: 'sdkVersion' }, { name: 'CPU Info', value: 'cpuInfo' }
  ];

  public static RFInfoProperties: NameValue<string>[] = [
    { name: 'Interface Mac', value: 'interfaceMac' }, { name: 'IPV4', value: 'ipv4' }, { name: 'IPV6', value: 'ipv6' }, { name: 'FW Version', value: 'fwVersion' }, { name: 'HW Version', value: 'hwVersion' }, { name: 'SDK Version', value: 'sdkVersion' }, { name: 'Channel', value: 'channel' }, { name: 'PAN Id', value: 'panId' }, { name: 'TX Power', value: 'txPower' }, { name: 'Neighbour Count', value: 'rplNeighbourCount' }, { name: 'RPL Rank', value: 'rplRank' }, { name: 'No. of Child', value: 'rplNumOfChild' },
    { name: 'RPL Dio Dbl Interval', value: 'rplDioDblInterval' }, { name: 'RPL Dio Lifetime', value: 'rplDaoLifetime' }, { name: 'RPL ETX Threshold Multiplier', value: 'rplEtxThresholdMultiplier' }, { name: 'RPL LQI Threshold', value: 'rplLqiThreshold' }];


  public static ppp0Infoproperties: NameValue<string>[] = [{ name: 'Interface Mac', value: 'interfaceMac' }, { name: 'IPV4', value: 'ipv4' }, { name: 'IPV6', value: 'ipv6' }, { name: 'Net Mask', value: 'netMask' }, { name: 'GW Mask', value: 'gwMask' }, { name: 'IMEI Number', value: 'imeiNumber' }];

  public static loInfoProperties: NameValue<string>[] = [{ name: 'Interface Mac', value: 'interfaceMac' }, { name: 'IPV4', value: 'ipv4' }, { name: 'Net Mask', value: 'netMask' }, { name: 'GW Mask', value: 'gwMask' }]
}

